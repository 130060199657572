<script setup lang="ts">
import type { FormContext } from 'vee-validate';

export interface Props {
    disabled?: boolean;
    form?: FormContext<any>;
    label?: string;
    minLength?: string;
    maxLength?: string;
    modelValue?: FieldTextValue;
    name: string;
    required?: boolean;
    type: FormTextType;
    readOnly?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    disabled: false,
    form: undefined,
    label: '',
    minLength: undefined,
    maxLength: undefined,
    modelValue: undefined,
    readOnly: false,
});

const emit = defineEmits<{
    'update:modelValue': [updatedValue: FieldTextValue];
    blur: [];
}>();

const {
    handleBlur,
    handleChange,
    meta: fieldMeta,
    validate,
    value: fieldValue,
} = useField<FieldTextValue>(props.name, undefined, {
    form: props.form,
    standalone: props.modelValue !== undefined,
});

/** Uses modelValue prop if it exists and uses vee-validate if not. */
const modelValue = computed({
    get: () => {
        if (props.modelValue !== undefined) {
            return props.modelValue;
        } else {
            return fieldValue.value;
        }
    },
    set: (updatedValue: FieldTextValue) => {
        if (props.modelValue === undefined) {
            handleChange(updatedValue);
        }
        emit('update:modelValue', updatedValue);
    },
});

const isInvalid = computed(() => fieldMeta.touched && !fieldMeta.valid);

/** Updates fieldMeta using handleBlur, then validates field. Emit blur event */
const onBlur = () => {
    handleBlur();
    validate();
    emit('blur');
};

const id = useId();
</script>

<template>
    <FormsFieldWrap
        :id="id"
        :hide-label="props.type === 'hidden'"
        :label="props.label"
        :meta="fieldMeta"
        :name="props.name"
        :required="props.required"
        :class="{ hidden: props.type === 'hidden' }"
    >
        <input
            :id="id"
            v-model="modelValue"
            :minlength="props.minLength"
            :maxlength="props.maxLength"
            :type="props.type"
            :disabled="props.disabled"
            :readonly="props.readOnly"
            :class="{
                input: true,
                invalid: isInvalid,
            }"
            @blur="onBlur"
        />
    </FormsFieldWrap>
</template>

<style lang="postcss" scoped>
.hidden {
    display: none;
}

.input {
    padding: 0 1rem;
    width: 100%;
    height: 2.75rem;
    line-height: 2.75rem;
    border: 1px solid var(--color-dark-gray);
    border-radius: var(--size-input-radius);
    outline-color: var(--color-blue);
    color: var(--color-font-dark);
    font-size: 1rem;
    font-family: var(--font-family);

    &.invalid {
        border-color: var(--color-error);
        outline-color: var(--color-error);
    }

    &[readonly] {
        cursor: not-allowed;
        background-color: var(--color-light-gray);
    }
}
</style>
