/**
 * @file Contains commonly used finance form validations
 */

import { email } from '@vee-validate/rules';
import { captureException } from '@sentry/vue';

export const validateString = (
    value: string | undefined,
    options: {
        required?: boolean;
        fieldName?: string;
        min?: number;
        max?: number;
    }
) => {
    if (value && options.min !== undefined && value.length < options.min) {
        return `Must be at least ${options.min} characters`;
    }
    if (value && options.max !== undefined && value.length > options.max) {
        return `Must be at most ${options.max} characters`;
    }

    if (options.required) {
        if (value?.trim()) return true;
        else if (options.fieldName) return `${options.fieldName} is required`;
        else return 'This field is required';
    }

    return true;
};

export const validateNumber = (
    value: number | string | null | undefined,
    options: {
        required?: boolean;
        fieldName?: string;
        min?: number;
        max?: number;
        allowDecimals?: boolean;
    }
) => {
    if (
        typeof value === 'number' &&
        options.min !== undefined &&
        value < options.min
    ) {
        return `Must be greater than ${options.min - 1}`;
    }
    if (
        typeof value === 'number' &&
        options.max !== undefined &&
        value > options.max
    ) {
        return `Must be less than ${options.max + 1}`;
    }
    if (
        typeof value === 'number' &&
        !options.allowDecimals &&
        value % 1 !== 0
    ) {
        return 'Must not contain decimals';
    }
    if (options.required) {
        if (value !== '' && value !== null && value !== undefined) return true;
        else if (options.fieldName) return `${options.fieldName} is required`;
        else return 'This field is required';
    }
    return true;
};

export const validateBoolean = (
    value: boolean | null | undefined,
    options: {
        required?: boolean;
        fieldName?: string;
        isTrue?: boolean;
    }
) => {
    if (options.required) {
        if (options.isTrue && !value) return `${options.fieldName} is required`;
        else if (value !== null && value !== undefined) return true;
        else return 'This field is required';
    }
    if (options.isTrue && value !== true) {
        if (options.fieldName) return `${options.fieldName} is required`;
        else return 'This field is required';
    }
    return true;
};

export const regexPhone = /^\d{3}-\d{3}-\d{4}$/;
export const validatePhone = (
    value: string | undefined,
    options?: {
        required?: boolean;
        fieldName?: string;
    }
) => {
    try {
        if (value && !regexPhone.test(value)) {
            return 'Must be a valid phone number: 555-555-1234';
        }
    } catch (err) {
        captureException(err, {
            extra: {
                regex: regexPhone,
                testedValue: value,
            },
        });
        console.error(err); // eslint-disable-line no-console
    }
    if (options?.required) {
        if (value?.trim()) return true;
        else if (options.fieldName) return `${options.fieldName} is required`;
        else return 'This field is required';
    }
    return true;
};

export const validateEmail = (
    value: string | undefined,
    options?: {
        required?: boolean;
        fieldName?: string;
    }
) => {
    if (value && !email(value)) {
        return 'This must be a valid email';
    }
    if (options?.required) {
        if (value?.trim()) return true;
        else if (options.fieldName) return `${options.fieldName} is required`;
        else return 'This field is required';
    }
    return true;
};

export const regexSsn = /^\d{3}-\d{2}-\d{4}$/;
export const validateSsn = (
    value: string | undefined,
    options?: {
        required?: boolean;
        fieldName?: string;
    }
) => {
    try {
        if (value && !regexSsn.test(value)) {
            return 'Must be a valid SSN: 123-45-6789';
        }
    } catch (err) {
        captureException(err, {
            extra: {
                regex: regexSsn,
                testedValue: value,
            },
        });
        console.error(err); // eslint-disable-line no-console
    }
    if (options?.required) {
        if (value?.trim()) return true;
        else if (options.fieldName) return `${options.fieldName} is required`;
        else return 'This field is required';
    }
    return true;
};

export const regexZip = /^\d{5}$/;
export const validateZip = (
    value: string | undefined,
    options?: {
        required?: boolean;
        fieldName?: string;
    }
) => {
    try {
        if (value && !regexZip.test(value)) {
            return 'Must be a valid zip code: 55555';
        }
    } catch (err) {
        captureException(err, {
            extra: {
                regex: regexZip,
                testedValue: value,
            },
        });
        console.error(err); // eslint-disable-line no-console
    }
    if (options?.required) {
        if (value?.trim()) return true;
        else if (options.fieldName) return `${options.fieldName} is required`;
        else return 'This field is required';
    }
    return true;
};

export const regexGuid =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const validateGuid = (
    value: string | undefined,
    options?: {
        required?: boolean;
        fieldName?: string;
    }
) => {
    try {
        if (value && !regexGuid.test(value)) {
            return 'Must be a valid GUID';
        }
    } catch (err) {
        captureException(err, {
            extra: {
                regex: regexGuid,
                testedValue: value,
            },
        });
        console.error(err); // eslint-disable-line no-console
    }
    if (options?.required) {
        if (value?.trim()) return true;
        else if (options.fieldName) return `${options.fieldName} is required`;
        else return 'This field is required';
    }
    return true;
};

export const validatePassword = (
    value: string | undefined,
    options: {
        patterns: RegExp[];
        minPatterns: number; // Minimum number of patterns to pass
        required?: boolean;
        fieldName?: string;
        min?: number;
        max?: number;
    }
) => {
    if (value && options.min !== undefined && value.length < options.min) {
        return `Must be at least ${options.min} characters`;
    }
    if (value && options.max !== undefined && value.length > options.max) {
        return `Must be at most ${options.max} characters`;
    }
    if (options.patterns.length) {
        let count = 0;
        options.patterns.forEach((pattern) => {
            try {
                if (value && pattern.test(value)) count++;
            } catch (err) {
                captureException(err, {
                    extra: {
                        regex: pattern,
                        testedValue: value,
                    },
                });
                console.error(err); // eslint-disable-line no-console
            }
        });
        if (count < options.minPatterns) return 'Password does not match rules';
        return true;
    }
    if (options.required) {
        if (value?.trim()) return true;
        else if (options.fieldName) return `${options.fieldName} is required`;
        else return 'This field is required';
    }
    return true;
};

export const validateMultipleSelect = (
    value: {} | undefined,
    options: {
        required?: boolean;
        fieldName?: string;
    }
) => {
    if (options.required) {
        if (value) return true;
        else if (options.fieldName) return `${options.fieldName} is required`;
        else return 'This field is required';
    }

    return true;
};
